import React from 'react'
import { Button } from '@material-ui/core'
import modelguide from '../../../images/pdf/2020-model-guide.pdf'

export default function ModelGuide() {
    return (
        <>
            <a href={modelguide} target="_blank">
                <Button variant="contained" color="primary"><span className="text-white">Download Model Guide</span></Button>
            </a>
        </>
    )
}

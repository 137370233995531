import React from 'react'
import { Button } from '@material-ui/core'
import ownersguide from '../../../images/pdf/2020-ownersguide-esms-digital.pdf'

export default function OwnersGuide() {
    return (
        <>
            <a href={ownersguide} target="_blank">
                <Button variant="contained" color="primary"><span className="text-white">Download Owners Guide</span></Button>
            </a>
        </>
    )
}
